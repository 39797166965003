import PlanCard from '../Cards/PlanCard'
import CompanionCard from '../Cards/CompanionCard'
import PaymentCard from '../Cards/PaymentCard'
import { SubscriptionType, RecurlyAccount } from '../../types/types'
import { getSubscriptionInfoAlert } from './SubscriptionHelpers'
import { formatDate } from '../../utils'

type propTypes = {
  subscription: SubscriptionType
  account: RecurlyAccount
}

export default function SubcriptionOverview({
  subscription,
  account,
}: propTypes) {
  return (
    <div>
      {account.billingInfo ? (
        getSubscriptionInfoAlert(subscription)
      ) : (
        <div className="alert alert-warning">
          Your subscription will expire on{' '}
          <strong>{formatDate(subscription.expiryDate)}</strong> if you
          don&apos;t add payment details.
        </div>
      )}
      <div className="d-lg-flex justify-content-lg-between mb-20 mb-lg-40">
        <PlanCard subscription={subscription} />
        <PaymentCard subscription={subscription} account={account} />
        <CompanionCard />
      </div>
    </div>
  )
}
