import styles from '../../styles/Card.module.scss'
import RenewalPriceComponent from '../Subscription/RenewalPriceComponent'
import { formatDate } from '../../utils'
import { SubscriptionType, RecurlyAccount } from '../../types/types'

type propTypes = {
  subscription?: SubscriptionType
  account?: RecurlyAccount
}

export default function PaymentCard({ subscription, account }: propTypes) {
  return (
    <div
      className={`d-flex flex-column border rounded p-10 p-lg-20 w-100 mb-10 mx-lg-5 justify-content-top ${styles.card}`}>
      <div className="text-center mb-10 mb-lg-30">
        <b>Next Payment</b>
      </div>
      <div className={`text-center ${styles.overviewCardBody} font-montserrat`}>
        <div>
          {!account?.billingInfo && (
            <>Add your payment info to keep subscribing</>
          )}
          {account?.billingInfo &&
            subscription &&
            subscription.state === 'active' && (
              <>
                <RenewalPriceComponent subscription={subscription} /> <br />
                <small>{formatDate(subscription.expiryDate)}</small>
              </>
            )}
        </div>
      </div>
    </div>
  )
}
