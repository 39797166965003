import styles from '../../styles/Card.module.scss'
import { formatDate, formatPlanName } from '../../utils'
import { SubscriptionType } from '../../types/types'

type propTypes = {
  subscription?: SubscriptionType
}

export default function PlanCard({ subscription }: propTypes) {
  const noPlan =
    !subscription || !['active', 'canceled'].includes(subscription.state)
  const inTrial = !noPlan && subscription?.isTrial

  function makeBody() {
    if (noPlan || !subscription?.planName) {
      return 'No active plan'
    }

    if (inTrial) {
      return (
        <>
          Trial <br />
          <small>until {formatDate(subscription!.expiryDate)}</small>
        </>
      )
    }
    return formatPlanName(subscription.planName)
  }

  return (
    <div
      className={`d-flex flex-column border rounded p-10 p-lg-20 w-100 mb-10 mx-lg-5 justify-content-top ${styles.card}`}>
      <div className="text-center mb-10 mb-lg-30">
        <b>Your Plan</b>
      </div>
      <div className={`text-center ${styles.overviewCardBody} font-montserrat`}>
        <div role="alert">{makeBody()}</div>
      </div>
      <div className="text-center font-montserrat"></div>
    </div>
  )
}
