import styles from '../../styles/Card.module.scss'

export default function OverviewCard() {
  const isWindows = navigator.userAgent.indexOf('Win') !== -1
  const winUrl =
    'https://nautilus.reasonstudios.com/latest_companion_installer/win/'
  const macUrl =
    'https://nautilus.reasonstudios.com/latest_companion_installer/mac/'

  return (
    <div
      className={`d-none d-sm-flex flex-column border rounded p-10 p-lg-20 w-100 mb-10 mx-lg-5 justify-content-top ${styles.card}`}>
      <div className="text-center mb-20">
        <b>Reason Companion</b>
      </div>
      <div className="d-flex align-items-top justify-content-center justify-content-lg-left mb-20">
        <div
          style={{ minWidth: '100px', paddingTop: '4px', paddingLeft: '12px' }}
          className="d-none d-lg-block">
          <img
            src={'../logos/companion.webp'}
            alt="companion logo"
            height="90px"
          />
        </div>
        <div className="ml-lg-15" style={{ fontSize: '16px' }}>
          Download the companion app to start making music with Reason!
        </div>
      </div>
      <div className="d-flex flex-column align-items-center">
        <div>
          <a
            href={isWindows ? winUrl : macUrl}
            className="btn btn-light text-nowrap">
            <i className={`fa fa-${isWindows ? 'windows' : 'apple'}`}>
              &nbsp;&nbsp;
            </i>
            Download for {isWindows ? 'windows' : 'mac'}
          </a>
        </div>
        <div>
          <a href={isWindows ? macUrl : winUrl} className="text-small">
            Download for {isWindows ? 'mac' : 'windows'}
          </a>
        </div>
      </div>
    </div>
  )
}
