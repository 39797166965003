import { useContext } from 'react'
import * as Sentry from '@sentry/nextjs'
import SubcriptionOverview from '../Subscription/SubcriptionOverviewComponent'
import { RecurlyAccount, SubscriptionType, isError } from '../../types/types'
import ProfileOverview from './ProfileOverview'
import Link from 'next/link'
import {
  UserContext,
  SubscriptionContext,
  RecurlyAccountContext,
} from '../context'
import { NondescriptError } from '../NondescriptError'

export default function Overview() {
  const { subscription, loading: subscriptionLoading } =
    useContext(SubscriptionContext)

  const reasonAccount = useContext(UserContext)
  const { account } = useContext(RecurlyAccountContext)

  let reasonPlusHeader = (
    <div className="mb-40">
      <p>
        You do not have a Reason+ Subscription. Get one{' '}
        <Link href="/signup">here!</Link>
      </p>
    </div>
  )

  if (isError(subscription) || isError(account)) {
    reasonPlusHeader = (
      <div className="mb-40">
        <NondescriptError message="Failed to fetch information related to payments and subscriptions." />
      </div>
    )
  } else if (subscription && account) {
    reasonPlusHeader = (
      <SubcriptionOverview account={account} subscription={subscription} />
    )
  }

  return (
    <div className="mb-60">
      <h2 className="h4">Overview</h2>
      <hr />
      <>
        <div className="mb-40">
          <h4>Reason+ Subscription</h4>
          {reasonPlusHeader}
          <h4>My Products</h4>
          <a href={`${process.env.NEXT_PUBLIC_CLOWN_URL}/user/products`}>
            Click here
          </a>{' '}
          to view your registered Rack Extensions, ReFills and Applications.
        </div>
        <ProfileOverview account={reasonAccount || undefined} />
      </>
    </div>
  )
}
