import { ReasonAccount, RecurlyAccount } from '../../types/types'
import Spinner from '../Spinner'
import Link from 'next/link'

type propTypes = {
  account?: ReasonAccount
}

export default function ProfileOverview({ account }: propTypes) {
  if (account) {
    return (
      <div className="mb-40">
        <h4>Profile</h4>
        <div className="plus-card mb-20">
          <table className="w-100">
            <thead className="plus-card-description">
              <tr>
                <th className="p-15 text-nowrap">Name</th>
                <th className="p-15 text-nowrap">Email</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="p-15 text-nowrap">{`${account.firstName} ${account.lastName}`}</td>
                <td className="p-15 text-nowrap">{account.email}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="">
          <Link href="/profile">Update profile</Link>
        </div>
      </div>
    )
  } else {
    return <Spinner />
  }
}
