import { Invoice, SubscriptionType } from '../../types/types'
import { useEffect, useState } from 'react'

import { formatCurrency } from '../../utils'
import { getPreviewRenewal } from '../../services/SubscriptionApiClient'

interface propTypes {
  subscription: SubscriptionType
}

export default function RenewalPriceComponent(props: propTypes) {
  const { subscription } = props
  const [renewal, setRenewal] = useState<Invoice>()

  useEffect(() => {
    async function getRenewal() {
      if (subscription && subscription.state === 'active') {
        setRenewal(await getPreviewRenewal(subscription.id))
      }
    }
    getRenewal()
  }, [subscription])

  return (
    <>
      {renewal ? (
        <div className="d-inline">
          {formatCurrency(renewal.total, subscription.currency)}
        </div>
      ) : (
        <></>
      )}
    </>
  )
}
